export const REGISTER_APP = 'REGISTER_APP';

export const RESET = 'RESET'

export const ADD_POINTS = 'ADD_POINTS';

export const ADD_POINT = 'ADD_POINT';
export const SET_POINT_POSITION = 'SET_POINT_POSITION';
export const DELETE_POINT = 'DELETE_POINT';

export const GENERATE_TRIANGLES = 'GENERATE_TRIANGLES';
export const UPDATE_TRIANGLES = 'UPDATE_TRIANGLES'

export const COLOR_TRIANGLE = 'COLOR_TRIANGLE';
export const COLOR_TRIANGLES = 'COLOR_TRIANGLES';

export const SET_THEME = 'SET_THEME';
export const REGISTER_THEME = 'REGISTER_THEME'
export const LOAD_IMAGE_THEME = 'LOAD_IMAGE_THEME';
export const ADD_IMAGE_THEME = 'ADD_IMAGE_THEME';
export const ADD_PALETTE_THEME = 'ADD_PALETTE_THEME';

export const UPDATE_POINT_SETTINGS = 'UPDATE_POINT_SETTINGS';
export const SET_TARGET_RESOLUTION = 'SET_TARGET_RESOLUTION';